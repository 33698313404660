import { useEffect } from 'react';
import _, { isEqual } from 'lodash';
import { authorize } from 'services/auth';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { useAuthFailureCount } from 'store/auth-failure-count';
import Container from 'components/Container/Container';
import { PayrollProviderId } from 'constants/types';
import { getStatusCode, validateSubdomain } from './utils';
import LoadingSpinner from '../LoadingSpinner';
import { getProviderComponent } from './providerToComponentMap';
import RegisterProviderContainer from './RegisterProviderContainer';
import useImplementations from './useImplementations';
import NoticeMessage from '../Messages/Notice';
import { useStagedConfigStore } from '../useStagedConfig';
import { Clients } from '../../../constants/clients';
import { GoBackButton } from 'components/Button/GoBackButton';
import { ImplementationKind, isAssistedImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { EMPLOYER_PREFIX } from 'constants/products';
import { useRegisterStep } from 'services/register-step';
import { step as stepValues } from '../steps';
import { Session } from '@finch-api/common/dist/internal/connect/validate';
import { shouldTriggerAuthFallback } from 'lib/auth-fallback/utils';
import { FinchErrorCode } from 'constants/finch-error-code';
import { AUTOMATED_AUTH_INSTRUCTIONS } from 'constants/automated-auth-instructions';
export type SubmitOptions = {
  subdomain?: string;
  fullDomain?: string;
  username?: string;
  password?: string;
  apiToken?: string;
  companyId?: string;
  providerClientId?: string;
  providerClientSecret?: string;
  providerRedirectUri?: string;
  isolvedUrl?: string;
  clientCode?: string;
  SID?: string;
  step?: string;
  reportUrl?: string;
  tenantId?: string;
  apiBaseUrl?: string;
  codeVerifier?: string;
  codeChallenge?: string;
  codeChallengeMethod?: string;
  idpRedirect?: boolean;
};

// if the connection is connected, the products haven't changed, and the assisted status is not connected,
// then we need to show just the manual sign in screen
const hasConnectionScopesChanged = (connection: Session['connection'], products: string[]) => {
  return connection && connection.automatedStatus === AccountStatus.CONNECTED &&
  // We will only skip the automated screen if the existing connection
  // scopes match match the requested products
  isEqual([...connection.automatedScopes, ...connection.assistedScopes].map(scope => scope.replace(EMPLOYER_PREFIX, '')), products) && connection.assistedStatus && connection.assistedStatus !== AccountStatus.CONNECTED;
};
const SignIn = ({
  clientId,
  connectionId,
  connection,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  providersConfig,
  payrollProvider,
  handleAuthorizeResponse,
  sandbox,
  client,
  prevStep,
  setCurrentStep,
  sdkVersion,
  appType,
  hasBenefitsProduct,
  implementationHasAssistedBenefits,
  setProviderImplementation,
  currentStep,
  setProviderToRedirect,
  error,
  setError,
  authorizeLoading,
  setAuthorizeLoading,
  idpRedirect,
  setRequestFinished,
  setDelayedAuthorizeLoading
}: AuthorizeContextValue) => {
  const authFailureCounts = useAuthFailureCount(state => state.authFailureCounts);
  const registerStep = useRegisterStep();
  if (!payrollProvider) throw new Error('no payroll provider');
  const stagedConfig = useStagedConfigStore(state => state.stagedConfig);
  const {
    implementationKind,
    onSwitchImplementationKind,
    implementationDetail
  } = useImplementations({
    provider: payrollProvider,
    setError,
    idpRedirect
  });
  useEffect(() => {
    setProviderImplementation(implementationDetail);
  }, [implementationDetail, setProviderImplementation, registerStep]);
  useEffect(() => {
    if (isAssistedImplementationKind(implementationKind) || hasConnectionScopesChanged(connection, products || []) || shouldTriggerAuthFallback(client, authFailureCounts, implementationDetail)) {
      setCurrentStep(stepValues.MANUAL_SIGN_IN);
    }
  }, [authFailureCounts, client, connection, implementationDetail, implementationKind, products, setCurrentStep]);
  if (!client) return <Container>{!client && <LoadingSpinner />}</Container>;
  if (!providersConfig) throw new Error('no provider configs');
  if (!products) throw new Error('no products');

  // it should either return api or credential implementation detail
  if (!implementationDetail) throw new Error(`${payrollProvider.id} ${implementationKind} kind does not have an implementation detail`);
  const ImplementationComponent = getProviderComponent(payrollProvider.id as PayrollProviderId, implementationKind);
  if (!ImplementationComponent) return <Container>{`Automated flows do not support the ${implementationKind} authentication method.`}</Container>;
  const handleSubmit = async (fieldToValueMap: SubmitOptions) => {
    const {
      subdomain,
      fullDomain,
      username,
      password,
      apiToken,
      companyId,
      providerClientId,
      providerClientSecret,
      providerRedirectUri,
      clientCode,
      SID,
      step,
      reportUrl,
      tenantId,
      apiBaseUrl,
      codeVerifier,
      codeChallenge,
      codeChallengeMethod,
      isolvedUrl
    } = fieldToValueMap;
    setRequestFinished(false);
    setAuthorizeLoading(true);
    setError(null);
    if (stagedConfig) {
      setError({
        message: 'You cannot complete a Connect session while in preview mode.',
        omitSessionKey: true,
        status: null
      });
      setDelayedAuthorizeLoading(false);
      return;
    }
    if (subdomain) {
      if (!validateSubdomain(subdomain)) {
        setError({
          message: 'Invalid subdomain. Subdomain should contain no spaces, symbols, or http prefixes',
          omitSessionKey: true,
          status: null
        });
        setDelayedAuthorizeLoading(false);
        return;
      }
      setError(null);
    }
    try {
      // Record that the user submitted the sign in form and reached that step
      registerStep({
        step: currentStep,
        providerId: payrollProvider.id
      });
      const authorizeResponse = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        username,
        password,
        providerClientId,
        providerClientSecret,
        providerRedirectUri,
        companyId,
        clientCode,
        domain: subdomain,
        fullDomain,
        reportUrl,
        tenantId,
        apiBaseUrl,
        apiToken,
        SID,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: implementationDetail.id,
        sandbox,
        sdkVersion,
        step,
        appType,
        codeChallenge,
        codeChallengeMethod,
        codeVerifier,
        implementationHasAssistedBenefits,
        isolvedUrl
      });
      handleAuthorizeResponse(authorizeResponse);
    } catch (err: any) {
      const finchCode = err?.response?.data?.finch_code;
      if (finchCode === FinchErrorCode.IncorrectPayrollProvider) {
        const correctPayrollProvider = _.get(err, 'response.data.correct_payroll_provider');
        setProviderToRedirect(correctPayrollProvider);
      }
      setError({
        message: err.message || 'An unexpected error has occurred. Please try again.',
        status: getStatusCode(err),
        finchCode
      });
      setDelayedAuthorizeLoading(false);
    }
  };
  const handleMockOAuth = async () => {
    const isFinchSandbox = sandbox === 'finch';
    const isDemoApp = clientId === Clients.Finch.Homepage;
    if (!isFinchSandbox && !isDemoApp) {
      return false;
    }
    if (isFinchSandbox) {
      await handleSubmit({
        step: 'NEW_SANDBOX_OAUTH_CONNECTION'
      });
      return true;
    }
    if (isDemoApp) {
      await handleSubmit({});
      return true;
    }
    return false;
  };
  const instructionsLink = AUTOMATED_AUTH_INSTRUCTIONS[payrollProvider.id as PayrollProviderId]?.[implementationDetail.kind.replace('finch_sandbox_', '') as ImplementationKind];
  return <>
      {providersConfig.length > 1 && <GoBackButton onClick={() => prevStep()} />}
      <RegisterProviderContainer client={client} error={error} setError={setError} provider={payrollProvider} products={products} hasBenefitsProduct={hasBenefitsProduct} currentStep={currentStep} implementationDetail={implementationDetail} sandboxMode={sandbox} connection={connection} data-sentry-element="RegisterProviderContainer" data-sentry-source-file="SignIn.tsx">
        {sandbox === 'provider' && hasBenefitsProduct && implementationHasAssistedBenefits && <NoticeMessage>
              The benefits product is being requested, but assisted benefits is
              not supported in Sandbox mode. Any generated tokens for this
              provider will not have benefits scopes.
            </NoticeMessage>}
        <ImplementationComponent client={client} provider={payrollProvider} implementationDetail={implementationDetail} isLoading={authorizeLoading} onSubmit={handleSubmit} handleClick={onSwitchImplementationKind} products={products} sandboxMode={sandbox} instructionsLink={instructionsLink} onMockOAuth={handleMockOAuth} data-sentry-element="ImplementationComponent" data-sentry-source-file="SignIn.tsx" />
      </RegisterProviderContainer>
    </>;
};
export default withAuthorizeContext(SignIn);