import { useRef, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { RegisterImplementationProps } from 'constants/types';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { getProviderFormLabel } from '../utils';
import SignInBase from '../SignInBase';
import LoaderButton from 'components/Button/LoaderButton';
const {
  protocol,
  host
} = window.location;
const JUSTWORKS_REDIRECT_URI = `${protocol}//${host}/auth/justworks`;
const SignInJustworksOAuth = ({
  client,
  provider,
  onSubmit,
  setError,
  handleClick
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);
  const knownState = `justworks-${uuidv1()}`;
  const config = (client as any)?.justworksApiConfig;
  if (!config?.oauthUrl) {
    throw new Error('Justworks API config is missing oauthUrl');
  }
  const {
    oauthUrl
  } = config;
  const closeWindow = () => {
    setLoading(false);
    externalWindowRef?.current?.close();
  };
  const handleSubmit = () => {
    setLoading(true);

    // Setup the request to initiate Justworks OAuth
    const url = new URL(oauthUrl);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('state', knownState);
    url.searchParams.set('scope', config.scopes.join(' '));
    url.searchParams.set('redirect_uri', JUSTWORKS_REDIRECT_URI);
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const receivedState = urlParams.get('state');
          if (receivedState !== knownState) return;
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              clientCode: code,
              providerRedirectUri: JUSTWORKS_REDIRECT_URI
            });
          }
          if (error) {
            setError({
              message: error,
              status: null
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e),
            status: null
          });
          closeWindow();
        }
      }
    }, 500);
  };
  const instructions = <p>
      Justworks authentication will continue in a new window. Please make sure
      to allow popups and finish authenticating through Justworks in the new
      window.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} data-sentry-element="SignInBase" data-sentry-component="SignInJustworksOAuth" data-sentry-source-file="SignInJustworksOAuth.tsx">
      <div style={{
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
        {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
            Use an Email Address and Password instead
          </SwitchImplementationButton>}
        <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} text="Connect" data-sentry-element="LoaderButton" data-sentry-source-file="SignInJustworksOAuth.tsx" />
      </div>
    </SignInBase>;
};
export default withAuthorizeContext(SignInJustworksOAuth);