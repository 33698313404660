import styled from 'styled-components';
import COLORS from 'constants/colors';
export const StyledRadio = styled.div`
  color: ${COLORS.GRAY.GRAY_900};
  display: flex;
  align-items: center;
  position: relative;
`;
export const StyledInput = styled.input`
  height: 18px;
  width: 18px;
  margin: 0px;
  appearance: none;

  position: relative;
  background-color: ${COLORS.WHITE};
  border: solid 1px ${COLORS.GRAY.GRAY_400};
  border-radius: 20px;
  cursor: pointer;
  padding: 1px;

  &:checked:after {
    content: '';
    background-color: ${props => props.color};
    border: solid 5px ${props => props.color};
    border-radius: 20px;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
export const StyledLabel = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 8px;
  line-height: 20px;
  cursor: ${(props: {
  disabled: boolean;
}) => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${(props: {
  disabled: boolean;
}) => props.disabled ? 0.6 : 1};
  font-size: 14px;
`;
const InputRadio = ({
  label,
  id,
  name,
  value,
  disabled = false,
  onChange
}: {
  label: string;
  id: string;
  name: string;
  value: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}) => <StyledRadio data-sentry-element="StyledRadio" data-sentry-component="InputRadio" data-sentry-source-file="InputRadio.tsx">
    <StyledInput type="radio" id={id} name={name} disabled={disabled} value={value} onChange={e => {
    if (onChange && e.target.checked) {
      onChange(e.target.value);
    }
  }} data-sentry-element="StyledInput" data-sentry-source-file="InputRadio.tsx" />
    <StyledLabel htmlFor={id} disabled={disabled} data-sentry-element="StyledLabel" data-sentry-source-file="InputRadio.tsx">
      {label}
    </StyledLabel>
  </StyledRadio>;
InputRadio.defaultProps = {
  disabled: false
};
export default InputRadio;