import { Heading, Text, Stack, VStack } from '@chakra-ui/react';
import { AppShellButton } from 'components/Button/AppShellButton';
import { ContainerOverlay } from 'components/Container/ContainerOverlay';
import { WarningIcon } from 'components/Icons/BigWarningIcon';
import InputRadio from 'components/Input/InputRadio';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import COLORS from 'constants/colors';
import { quit } from 'services/auth';
import styled from 'styled-components';
const StyledOptionsContainer = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid ${COLORS.GRAY.GRAY_400};
  border-radius: 8px;
  background-color: ${COLORS.GRAY.GRAY_100};
  font-size: 14px;
  width: 100%;
  color: ${COLORS.BLACK};
`;
const staticOptions = {
  TESTING_THE_FLOW: 'I’m just testing the flow',
  PROCESS_TAKES_TOO_LONG: 'This process takes too long',
  REQUEST_PERMISSIONS_CAUSE_CONCERN: 'The requested permissions cause concern',
  PROVIDER_IS_NOT_LISTED: 'My provider isn’t listed',
  CONNECTION_ERROR: 'I hit a connection error',
  OTHER: 'Other'
};
const InputOther = styled.textarea`
  height: 72px;
  width: 100%;
  border-radius: 8px;
  resize: none;
  padding: 8px;
  border: 1px solid ${COLORS.GRAY.GRAY_400};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.FINCH.BLACK};

  &:focus {
    border: 1px solid ${COLORS.FINCH.PURPLE};
    outline: none;
  }
`;
interface SessionBasic {
  sessionKey: string;
  payrollProviderId?: string;
  appType: string;
  providerImplementationId?: string;
  acfFallbackEligible?: boolean;
  currentBrowserSessionKey: string;
  state?: string;
  step: string;
  clientId: string;
  sdkVersion?: string;
}
export const ExitPage = ({
  session,
  onClose,
  onGoBack
}: {
  session: SessionBasic;
  onClose: () => void;
  onGoBack: () => void;
}) => {
  const onSubmit = async ({
    reason,
    reasonContext
  }: {
    reason?: string;
    reasonContext?: string;
  }) => {
    const exitReason = !reason ? 'NO_ANSWER' : reason;
    const exitContext = reason === 'OTHER' && reasonContext ? reasonContext.trim() : '';
    await quit({
      ...session,
      exitReason,
      exitContext
    }).catch(_err => {
      // we fail this silent because this is optional and we don't want to prevent the user from quitting because of the survey
    });
    onClose();
  };
  return <ContainerOverlay isExitIntent data-sentry-element="ContainerOverlay" data-sentry-component="ExitPage" data-sentry-source-file="ExitPage.tsx">
      <MultiValueForm formLabel="Exit form" onSubmit={onSubmit} fieldNames={['reason', 'reasonContext']} defaultValues={{
      reason: 'NO_ANSWER',
      reasonContext: ' '
    }} render={({
      reason,
      reasonContext
    }) => {
      return <Stack>
              <Stack p="6" h="full" justify="space-between">
                <Stack py="4" gap="4" align="center">
                  <WarningIcon />
                  <Heading fontWeight="medium">Are you sure?</Heading>
                  <Text textAlign="center">
                    Your progress will be lost if you leave.
                  </Text>
                  <StyledOptionsContainer style={{
              flexDirection: 'column'
            }}>
                    <Stack gap="4">
                      <div>
                        <span style={{
                    fontWeight: '500',
                    color: COLORS.FINCH.BLACK
                  }}>
                          What’s your reason for leaving?
                        </span>{' '}
                        <span style={{
                    color: COLORS.GRAY.GRAY_600,
                    paddingLeft: '5px',
                    fontSize: '12px'
                  }}>
                          (Optional)
                        </span>
                      </div>
                      <VStack gap="4" align="start">
                        {Object.entries(staticOptions).map(([key, value]) => <InputRadio key={key} name="reason" label={value} value={key} id={`reason-${key}`} onChange={value => reason.onChange(value)} />)}
                        <InputOther placeholder="Share your feedback" style={{
                    display: `${reason.value === 'OTHER' ? 'block' : 'none'}`
                  }} name="reasonContext" onChange={e => reasonContext.onChange(e.target.value)} defaultValue={reasonContext.value.trim()}></InputOther>
                      </VStack>
                    </Stack>
                  </StyledOptionsContainer>
                </Stack>
              </Stack>
              <Stack style={{
          position: 'absolute',
          bottom: '24px',
          width: '100%',
          padding: '0 24px'
        }}>
                <AppShellButton type="submit">Yes, leave</AppShellButton>
                <AppShellButton variant="outline" onClick={onGoBack}>
                  No, go back
                </AppShellButton>
              </Stack>
            </Stack>;
    }} data-sentry-element="MultiValueForm" data-sentry-source-file="ExitPage.tsx" />
    </ContainerOverlay>;
};