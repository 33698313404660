import { OAuthRedirect } from './OAuthRedirect';
import DeelOauthCallback from '../SignIn/Deel/DeelOauthCallback';
import FactorialHROauthCallback from '../SignIn/FactorialHR/FactorialHROAuthCallback';
import { PaycorOauthCallback } from '../SignIn/Paycor/PaycorOAuthCallback';
import { RipplingOauthCallback } from '../SignIn/Rippling/RipplingOauthCallback';

export const customOauthCallbackRoutes = [
  {
    path: '/auth/deel',
    component: DeelOauthCallback,
  },
  {
    path: '/auth/gusto',
    component: OAuthRedirect,
  },
  {
    path: '/auth/justworks',
    component: OAuthRedirect,
  },
  {
    path: '/auth/bamboo_hr',
    component: OAuthRedirect,
  },
  {
    path: '/auth/oyster_hr',
    component: OAuthRedirect,
  },
  {
    path: '/auth/square_payroll',
    component: OAuthRedirect,
  },
  {
    path: '/auth/paycor',
    component: PaycorOauthCallback,
  },
  {
    path: '/auth/factorial_hr',
    component: FactorialHROauthCallback,
  },
  {
    path: '/auth/rippling',
    component: RipplingOauthCallback,
  },
];
