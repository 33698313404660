export const generateEmailWithRandomNumberAlias = (
  email: unknown,
  randNumberFn: () => number = () =>
    Math.floor(Math.random() * 900000) + 100000,
): string | null => {
  if (email === undefined) return null;
  if (typeof email === 'string') {
    try {
      const parts = email.split('@');
      const newEmail = `${parts[0]}+${randNumberFn()}@${parts[1]}`;
      return newEmail;
    } catch (err) {
      return null;
    }
  }
  // if there is no configured email or some error above, default whatever was passed in
  return null;
};

export const removeNumbers = (str: string) => {
  return str
    .replace(/\d/g, ' ')
    .split(' ')
    .filter((seg) => seg.length !== 0)
    .join(' ');
};
